import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import "../../assets/main.css";
import Layout from "./BusinessLayout.js"

class BusinessCity extends React.Component {
   
    constructor(props) {
    super(props)
    this.state = {
            data: [],
            cities: [],
            page: this.props.page,
        }
    }
   async componentDidMount() {
    this.getData();
  }
    
    async getData(){
        let res = await fetch("https://api.working-capital.com/states/"+this.props.state + "/cities/"+this.state.page,{
      headers: {
        Accept: "application/json",
      },
    method:'GET',
    });
    let json = await res.json();
    let c = json["arr"];
    console.log(json);
    this.setState({ data : json});
    this.setState({cities : c});
	}
    
    toggle(i){
        this.setState({page : i});
	}

    componentDidUpdate(prevProps, prevState) {
    // update state
    console.log("CDU", prevProps, prevState);

    if (prevState.page !== this.state.page) {
      //api call
      this.getData();
    }
  }

 

    render(){
       console.log(JSON.stringify(this.state.cities));
       const mainContent = (
            <div className = "text-center">
             <p className="m-10 p-10 text-3xl">Sort Businesses by City in {this.props.state}</p>
             
             <div>
                

                {this.state.cities.map(city => 
                (<Link to={"/business-directory/states/"+this.props.state+"/cities/"+city["url"]+"/businesses/1"} style={{ textDecoration: "none" }}> 
                <div className="inline-block text-sm bg-gray-200 rounded mr-2 mb-1 p-1 rounded-sm hover:bg-gray-300">{city["city"]}</div>
                 </Link>)
                )}
             </div>
            </div>
          
       );
    
        return(
           
            <div>
            <Layout mainContent={mainContent} />
            <div className="justify-center text-center p-4">
                <div className="flex justify-center items-center">
                    
                    {Array.from({ length: Math.ceil(this.state.data["count"]/this.state.data["limit"]) }, (_, i) => (
                    <div>
                           
                            {this.props.page == i+1 ?
                                <div style={{width: "20px", height: "25px"}} className="bg-gray-200 m-2 p-5 shadow-lg text-md border border-solid border-20 border-gray-600 text-black">
                         
                                    <Link className="no-underline" onClick={(e) => this.toggle(i + 1)} to={"/business-directory/states/"+this.props.state+"/cities/"+ (parseInt(i)+1)}>
                                        <div className="no-underline text-lg">{i + 1}</div>
                                    </Link>
                                </div>
                    :
                    <div style={{width: "20px", height: "20px"}} className="bg-white m-2 p-5 l-5 shadow-lg text-md border border-solid border-20 border-gray-600 text-black">
                        <Link className="no-underline" onClick={(e) => this.toggle(i + 1)} to={"/business-directory/states/"+this.props.state+"/cities/" + (parseInt(i)+1)}>
                            <div className="no-underline text-lg">{parseInt(i) + 1}</div>
                        </Link>
                    </div>
                    }
                </div>
                ))}
                    </div>
              </div>
            </div>
        )
	}
}

export default BusinessCity
    