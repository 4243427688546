import React from "react";
import { Link } from "gatsby";
import {Helmet} from "react-helmet";
import Img from "gatsby-image";
import "../../assets/main.css";
import Layout from "./BusinessLayout.js"
import mapboxgl from 'mapbox-gl';
import Navigation from '../navigation'

class Business extends React.Component {
 

   constructor(props) {
   
    super(props)
     this.state = {
            data: [],
            center: [0,0],
        }
    }

    async componentDidMount() {
    let res = await fetch("https://api.working-capital.com/states/"+this.props.state + "/cities/"+this.props.city+"/businesses/c/"+this.props.id,{
      headers: {
        Accept: "application/json",
      },
    method:'GET',
    });
    let json = await res.json();
    
    this.setState({ data : json});

    let address = "" + this.state.data["address"] + "+" + this.state.data["city"] + "+" + this.state.data["state"] + "+" + this.state.data["zip"];
    let c = await fetch(("https://api.mapbox.com/geocoding/v5/mapbox.places/" + encodeURIComponent(address)  +".json?limit=100&access_token=pk.eyJ1IjoiY2FyZGlmZmRldiIsImEiOiJja2FmeDg2am0wMThyMnFwbnJnd2MyaGxoIn0.vbcQhjU41S8WudzH3GwUrw"),{
     
    method:'GET',

	});
        let geo = await c.json();
        console.log(geo);
        this.setState({ center : geo['features'][0]["center"]});
     }

    render(){


        const mainContent = (
            
            <div className="justify-center text-center items-center bg-gray-100 mr-10 ml-10">
          
            
            <div className="">
                <div className="flex">
                   <div className="w-full lg:w-3/5 justify-left p-10"> 
                        <h1 className="text-4xl text-left text-black font-bold">{this.state.data["name"]}</h1>
                        <h4 className="text-2xl text-left text-black font-bold">Address: {this.state.data["address"]}, {this.props.city}, {this.props.state}  </h4>
                        <div>
                            <div className="flex flex-wrap text-white text-2xl justify-center bg-gray-800 m-0">
                                <p>Contact Information: </p>
                              
                               
                            </div>
                            <div className="grid grid-rows text-black bg-gray-400 m-30 text-center">
                                     <h4 className="text-2xl text-black font-bold">Phone: <span style={{filter: 'blur(5px)'}}>123-456-7890</span>  </h4>
                                     <h4 className="text-2xl text-black font-bold">Email: <span style={{filter: 'blur(5px)'}}>abc@gmail.com</span>  </h4>
                                 </div>
                        </div>
                    </div>
                    <div className="w-full lg:w-2/5 justify-center pt-4">
                    {this.state.data["contact_title"]==null ? <></> : 
                    <div className="flex flex-wrap text-white text-2xl justify-center bg-gray-800 m-0">
                        <p>Business Information: </p>
                        
                    </div>}
                        <div className="grid grid-rows text-black justify-center bg-gray-400 m-30 text-center">
                            
                                {this.state.data["contact_name"]=="" && this.state.data["contact_title"] =="" ? <></> : <p><div className="text-lg font-bold">{this.state.data["contact_title"]}</div><div className="text-md">{this.state.data["contact_name"]}</div></p>}
                                {this.state.data["employees"]=="" ? <></> : <p><div className="text-lg font-bold">Employees</div><div className="text-md">{this.state.data["employees"]}</div></p>}
                                {this.state.data["sales"]=="" ? <></> : <p><div className="text-lg font-bold">Sales</div><div className="text-md">{this.state.data["sales"]}</div></p>}
                                {this.state.data["industry"]=="" ? <></> : <p><div className="text-lg font-bold">Industry</div><div className="text-md">{this.state.data["industry"]}</div></p>}
                                {this.state.data["secondary_indusrty"]==null ? <></> : <div className=" m-3 text-lg"><p>Secondary Industry<div className="text-md">{this.state.data["secondary_industry"]}</div></p></div>}
                                {this.state.data["start_year"]=="" ? <></> : <p><div className="text-lg font-bold">Founded</div><div className="text-md">{this.state.data["start_year"]}</div></p>}
                                {this.state.data["website"]==null ? <></> : <p><div className="text-lg font-bold">Website</div><div className="text-md">{this.state.data["website"]}</div></p>}

                            
                        </div>
                    </div>
                </div>
              </div>
            </div>
        );
        return(
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{this.state.data["name"]}</title>
                    <link href='https://api.mapbox.com/mapbox-gl-js/v1.12.0/mapbox-gl.css' rel='stylesheet' />
                </Helmet>
                <Navigation />
                <Map center={this.state.center} />
                
                {mainContent}
            </div>
        )
	}
}

export default Business
    
function Map(props) {
  const div = React.useRef(null);
  const [map, setMap] = React.useState(null);

  const [center, setCenter] = React.useState([0, 0]);

  React.useEffect(() => {
    console.log("div at this time", div);
    mapboxgl.accessToken =
      "pk.eyJ1IjoiY2FyZGlmZmRldiIsImEiOiJja2FmeDg2am0wMThyMnFwbnJnd2MyaGxoIn0.vbcQhjU41S8WudzH3GwUrw";
    setMap(
      new mapboxgl.Map({
        container: div.current,
        style: "mapbox://styles/cardiffdev/ckafys5un06a11ip3cdkvpeuf",
        zoom: 17,
        center: center,
      })
    );
  }, []);

  React.useEffect(() => {
    if (!map) {
      return;
    }

    if (center != props.center) {
      console.log("map center should change now", props.center);
      setCenter(props.center);
      map.jumpTo({ center: props.center });
      new mapboxgl.Marker().setLngLat(props.center).addTo(map);
    }
  }, [props.center, center, map]);

  const style = {
    height: "350px",
    width: "100%",
    margin: "auto",
    marginTop: 20,
  };

  return <div style={style} ref={div} />;
}