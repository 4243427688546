import React from 'react'
import {Helmet} from "react-helmet";

import Container from '../container'

import Navigation from '../navigation'
import '../../assets/main.css'
import Compare from '../compare-components/dropdown-compare.js'
import Loan from '../compare-components/loan-svg.js'

class Template extends React.Component {
  constructor(props){
      super(props);
  }
  
  render() {
    const { children } = this.props;

    return (
      <div style={{ background: '#fff' }}> {/* Taking this out ruins navbar color for some reason */}
      <Helmet>
                <meta charSet="utf-8" />
                <title>My Title</title>
                <link href='https://api.mapbox.com/mapbox-gl-js/v1.12.0/mapbox-gl.css' rel='stylesheet' />
      </Helmet>
      <Navigation />
      
      <Container>
            <div className="flex" style={{flexWrap: 'wrap',}}>
                  <div className="w-full relative">
                        {this.props.mainContent}
                        
                        
                   </div>
                    
            </div>
      </Container>

    </div>
    )
  }
}

export default Template
