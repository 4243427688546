import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import "../../assets/main.css";
import Layout from "./BusinessLayout.js"

class BusinessState extends React.Component {
   
    constructor(props) {
    super(props)
    this.state = {
            data: [],
        }
        
    }

   async componentDidMount() {
    let res = await fetch("https://api.working-capital.com/states", {
      headers: {
        Accept: "application/json",
      },
    method:'GET',
    });
    let json = await res.json();
    console.log(json);
    this.setState({ data : json});
  }

    render(){
       var x = JSON.stringify(this.state.data);
       const mainContent = (
            <div className = "text-center w-full">


            <div class="relative z-0 text-center rounded shadow-md flex card justify-center" style={{zIndex: "1", justify: "center",}}>
            <div class="h-full content-center" alt="Room Image" style={{height: "270px", filter: 'blur(1px)', filter: 'brightness(30%)', background: "./general-tablet.jpg", backgroundPosition: 'center', backgroundSize: 'cover', width: '100%'}}></div>
            <container class="absolute h-64">
            <div class="row h-100 content-center m-30">
            <div class="m-auto">
                <h1 className = "text-center text-black font-bold text-2xl md:text-4xl pt-16">
                    Business Directory 
                </h1>
                 <a class="no-underline" href="https://working-capital.com/apply/"><p class="text-center cursor-pointer">
                    <button class="mb-4 cursor-pointer text-lg bg-green-600 text-white text-center font-semibold py-3 px-12 mr-10 border-transparent hover:bg-green-800 transition">Looking for a Loan?</button>
                </p></a>
            </div>
            </div>
            </container>
        </div>




             <p className="m-5 p-5 text-3xl">Sort Businesses by State</p>
             
             <div>
                
                {this.state.data.map(state => 
                (<Link to={"/business-directory/states/" + state["state"] + "/"+ "cities/1"} style={{ textDecoration: "none" }}> 
                  <div className="inline-block text-sm bg-gray-200 rounded mr-2 mb-1 p-1 rounded-sm hover:bg-gray-300">{state["state"]}</div>
                </Link>)
                )}
             </div>
            </div>
          
       );
    
        return(
           
            <div>
            <Layout mainContent={mainContent} />
            </div>
        )
	}
}

export default BusinessState
    