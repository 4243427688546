import React from "react"
import { Router } from "@reach/router"
import Business from "../components/client-components/Business.js"
import BusinessState from "../components/client-components/BusinessState.js"
import BusinessCity from "../components/client-components/BusinessCity.js"
import BusinessInCity from "../components/client-components/BusinessInCity.js"

const App = () => {
  return (
      
      <Router basepath="/business-directory">
        <BusinessState path="/" />
        
        <BusinessCity exact path = "states/:state/cities/:page" />
        
        <BusinessInCity exact path = "states/:state/cities/:city/businesses/:page" />  
        <Business exact path = "/states/:state/cities/:city/c/:id/:name" /> 
        
      </Router>
  
  )
}
export default App